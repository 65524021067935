* {
    font-family: "IBM Plex Sans", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

body {
    /* background-color: #1c1d21; #ffe8d6 */
    background-color: #ffefe2;
    margin: 0;
}
